import React, { useState } from 'react';
import { Button } from 'antd';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { AdditionalComments } from 'common/components/Form/AdditionalCommentsModal';
import { GoBackwardConfirmationModal } from 'common/components/Form/GoBackwardConfirmationModal';
import { IPathParam } from 'common/models/formBuilder.models';
import {
  communicationPatientSessions,
  IPatientSessionsConnectedProps,
} from 'entities/PatientSessions/PatientSessions.communication';

interface IComponentProps {
  finish?: boolean;
  handleSave: (comment: string) => void;
  nextButtonDisabled?: boolean;
}

type AllProps = IPatientSessionsConnectedProps & RouteComponentProps<IPathParam> & IComponentProps;

export const Buttons: React.FC<AllProps> = (props) => {
  const { finish, handleSave, nextButtonDisabled, patientSessionsModel, getPrevPatientSessionsModel, match } = props;
  const patientSessionId = match.params.id;
  const [isClicked, setIsClicked] = useState<boolean>(false);
  const [isModalVivible, setIsModalVivible] = useState<boolean>(false);
  const { data, params } = patientSessionsModel;
  const isPrevBaselineBody = data?.prevTextId === 'baseline_body';

  const toggle = () => {
    setIsClicked(!isClicked);
  };

  const onSave = (comment: string) => {
    handleSave(comment);
    toggle();
  };

  const handleBackClick = () => {
    if (data && params) {
      getPrevPatientSessionsModel(patientSessionId);
    }
  };

  const showConfirmationModal = () => {
    setIsModalVivible(true);
  };

  const hideConfirmationModal = () => {
    setIsModalVivible(false);
  };

  const goToPainAreas = () => {
    hideConfirmationModal();
    getPrevPatientSessionsModel(patientSessionId);
  };

  const setClasses = () => {
    let className = '';

    if (!finish) {
      className += 'form__btns';
    } else {
      className += 'form__btns form__finish';
    }

    return className;
  };

  return (
    <div>
      <GoBackwardConfirmationModal visible={isModalVivible} onCancel={hideConfirmationModal} onSave={goToPainAreas} />
      <AdditionalComments
        onCancel={toggle}
        onSave={onSave}
        visible={isClicked}
        value={data?.comments?.length ? data?.comments[0]?.comment : ''}
      />
      <div className={setClasses()}>
        {!finish && (
          <Button className="form__btn" onClick={isPrevBaselineBody ? showConfirmationModal : handleBackClick}>
            Back
          </Button>
        )}
        <div className="form__btns_container">
          {data?.textId !== 'baseline_promis' || (data?.textId === 'baseline_promis' && !data?.isFinished) ? (
            <Button className="form__btn" onClick={toggle}>
              Any additional comments?
            </Button>
          ) : (
            <div />
          )}
          <Button type="primary" htmlType="submit" disabled={nextButtonDisabled}>
            {!finish ? 'Next' : 'Finish'}
          </Button>
        </div>
      </div>
    </div>
  );
};

export const ManageBtns = withRouter(communicationPatientSessions.injector(Buttons));
