import React, { RefObject, useEffect, useRef, useState } from 'react';
import { Row } from 'antd';
import { SketchField } from 'react-sketch3';
import { ICanvasOptions, IImageOptions, IObjectOptions, Path, Point } from 'fabric/fabric-impl';
import { CanvasControlButton } from 'common/components/Body/CanvasContolButton';
import { rgbToHex } from 'common/utils/rgbToHex';
import { IPainArea } from 'common/models/body.models';
import { IBodyPart } from 'common/models/painAreas.models';
import {
  PAIN_AREAS_DRAWING_COMPONENT_LINE_COLOR,
  PAIN_AREAS_DRAWING_COMPONENT_SELECT_TOOL_BORDER_COLOR,
  PAIN_AREAS_PAGE_CANVAS_HEIGHT,
  PAIN_AREAS_PAGE_CANVAS_WIDTH,
} from 'common/config';
import scar from 'app/assets/images/painAreas/scar.png';

const enum ETools {
  Pencil = 'pencil',
  Select = 'select',
}

interface IComponentProps {
  currentPart: IBodyPart;
  selectedAreas: IPainArea[];
  setSelectedAreas: React.Dispatch<React.SetStateAction<IPainArea[]>>;
  setCanvasData: React.Dispatch<React.SetStateAction<string>>;
  setSketchFieldRef: React.Dispatch<React.SetStateAction<RefObject<SketchField> | undefined>>;
  loading: boolean;
}

interface IPointCoords {
  x: number;
  y: number;
}

interface ICanvasEvent {
  target: TargetExtended;
}

type TargetExtended = Path & IImageOptions & Pick<ICanvasOptions, 'preserveObjectStacking'> & IObjectOptions;

export const PainAreasDrawing: React.FC<IComponentProps> = (props) => {
  const { currentPart, setCanvasData, selectedAreas, setSelectedAreas, loading, setSketchFieldRef } = props;
  const [tool, setTool] = useState<ETools>(ETools.Pencil);
  const [heatmapData, setHeatmapData] = useState<ImageData | null | undefined>(null);
  const [genitalsShown, setGenitalsShown] = useState<boolean>(false);
  const [localPainAreas, setLocalPainAreas] = useState<string[]>([]);
  const [localScarAreas, setLocalScarAreas] = useState<string[]>([]);
  const sketchFieldRef = useRef<SketchField>(null);
  const legsFrontShown = currentPart.name === 'Legs (Front)';

  const handleDraw = () => {
    setTool(ETools.Pencil);
  };

  const handleAddScar = () => {
    sketchFieldRef?.current?.addImg(scar, { top: PAIN_AREAS_PAGE_CANVAS_HEIGHT / 2, scale: 1 });
    setTool(ETools.Select);
  };

  const handleUndo = () => {
    if (sketchFieldRef?.current?.canUndo()) {
      sketchFieldRef.current.undo();
      const objects = sketchFieldRef?.current?._fc._objects;
      sketchFieldRef?.current.props.onObjectModified({ target: objects[objects.length - 1] });
    }
  };

  const handleClear = () => {
    // For every local area marked with pain decreases it's count in selectedAreas state by 1
    localPainAreas.forEach((area: string) => {
      const index = selectedAreas.findIndex((entry: IPainArea) => entry.name === area);

      if (index !== -1) {
        selectedAreas[index] = {
          name: selectedAreas[index].name,
          painMarksCount: selectedAreas[index].painMarksCount - 1,
          scarMarksCount: selectedAreas[index].scarMarksCount,
        };
      }

      const pos = currentPart.selectedAreas.findIndex((entry: IPainArea) => entry.name === area);

      if (pos > -1) {
        currentPart.selectedAreas[pos].painMarksCount -= 1;
      }
    });

    // For every local area marked with scar decreases it's count in selectedAreas state by 1
    localScarAreas.forEach((area: string) => {
      const index = selectedAreas.findIndex((entry: IPainArea) => entry.name === area);

      if (index !== -1) {
        selectedAreas[index] = {
          name: selectedAreas[index].name,
          painMarksCount: selectedAreas[index].painMarksCount,
          scarMarksCount: selectedAreas[index].scarMarksCount - 1,
        };
      }

      const pos = currentPart.selectedAreas.findIndex((entry: IPainArea) => entry.name === area);

      if (pos > -1) {
        currentPart.selectedAreas[pos].scarMarksCount -= 1;
      }
    });

    setSelectedAreas(excludeUnselectedZones(selectedAreas));
    sketchFieldRef?.current?.clear();
    setLocalPainAreas([]);
    setLocalScarAreas([]);
  };

  const handleChange = (evt?: PointerEvent): void => {
    // When shape was drawn
    if (evt?.type === 'pointerup' && tool === ETools['Pencil']) {
      setTool(ETools.Select);
    }

    setCanvasData(JSON.stringify(sketchFieldRef.current?.toJSON()));
    setSketchFieldRef(sketchFieldRef);
  };

  const toggleGenitalsState = () => {
    setGenitalsShown(!genitalsShown);
  };

  const getHeatmapResponse = (x: number, y: number): string | undefined => {
    // Here we get index of corresponding pixel red channel value
    // +1 stands for green, +2 - blue, +3 - alpha
    // Proof: https://stackoverflow.com/a/25392389
    if (heatmapData && x > 0 && y > 0) {
      const index = (y * heatmapData.width + x) * 4;
      const red = heatmapData.data[index];
      const green = heatmapData.data[index + 1];
      const blue = heatmapData.data[index + 2];
      const hexColor = rgbToHex(red, green, blue);

      return currentPart?.heatmapData[hexColor];
    }

    return undefined;
  };

  const iterateScarMarkArea = (x1: number, x2: number, y1: number, y2: number) => {
    const areas: string[] = [];

    // For every pixel of rectangle that enclose our scar mark in it - determine corresponding pain areas
    for (let x = x1; x <= x2; x++) {
      for (let y = y1; y <= y2; y++) {
        const area = getHeatmapResponse(x, y);
        if (area) {
          areas.push(area);
        }
      }
    }

    // There can be multiple entries for one area, no need to take all of them into account.
    return [...new Set(areas)];
  };

  // Provides real coordinates of our shape points accounting it's scaling, flipping and translation
  // Proof - http://fabricjs.com/docs/fabric.util.html#.transformPoint
  const transformPoint = ({ x, y }: IPointCoords, matrix: number[]) => {
    return {
      x: Math.trunc(matrix[0] * x + matrix[2] * y + matrix[4]),
      y: Math.trunc(matrix[1] * x + matrix[3] * y + matrix[5]),
    };
  };

  const determineAreas = (target: TargetExtended): string[] | undefined => {
    // Make shape controls look less ugly
    target.transparentCorners = false;
    target.cornerColor = PAIN_AREAS_DRAWING_COMPONENT_SELECT_TOOL_BORDER_COLOR;
    target.borderColor = PAIN_AREAS_DRAWING_COMPONENT_SELECT_TOOL_BORDER_COLOR;
    target.cornerSize = 10;
    target.lockScalingFlip = true;

    if (!target.cacheKey) {
      const areas: string[] = [];
      // Matrix keep such data about our shape as: scaleX, scaleY, flipX, flipY, translateX, translateY
      // Proof: http://fabricjs.com/docs/fabric.Path.html#calcTransformMatrix
      const matrix = target.calcTransformMatrix();
      // Path keeps every point of drawn shape implementing a Bezier curve
      // We need filter to exclude letter designations of corresponding point
      const path: number[] = [];

      target?.path?.flat().forEach((point: Point) => {
        if (!isNaN(point as unknown as number)) {
          path.push(Math.trunc(point as unknown as number));
        }
      });
      for (let i = 0; i < path.length - 1; i += 2) {
        // Even indexes - x coordinate, odd - y coordinate
        // Offset relative to our canvas element
        const xWithOffset = path[i] - target.pathOffset.x;
        const yWithOffset = path[i + 1] - target.pathOffset.y;
        const point = transformPoint({ x: xWithOffset, y: yWithOffset }, matrix);
        const area = getHeatmapResponse(point.x, point.y);

        if (area) {
          areas.push(area);
        }
      }
      // There can be multiple entries for one area, no need to take all of them into account.
      return [...new Set(areas)];
    } else {
      // aCoords - Describe object's corner position in canvas object absolute coordinates
      // Proof - http://fabricjs.com/docs/fabric.Path.html#aCoords
      // tl - describe coordinates of top-left corner
      // br - describe coordinates of back-right corner
      if (target.aCoords) {
        const x1 = Math.trunc(target.aCoords.tl.x);
        const x2 = Math.trunc(target.aCoords.br.x);
        const y1 = Math.trunc(target.aCoords.tl.y);
        const y2 = Math.trunc(target.aCoords.br.y);

        // We need these checks to handle the case when we turn our mark
        if (x1 < x2) {
          if (y1 < y2) {
            return iterateScarMarkArea(x1, x2, y1, y2);
          } else {
            return iterateScarMarkArea(x1, x2, y2, y1);
          }
        } else {
          if (y1 < y2) {
            return iterateScarMarkArea(x2, x1, y1, y2);
          } else {
            return iterateScarMarkArea(x2, x1, y2, y1);
          }
        }
      }
    }
    return undefined;
  };

  const handleObjectAdded = (e: ICanvasEvent) => {
    const newLocalObjectsSize = e.target?.canvas?._objects.length;
    const areas = determineAreas(e.target);

    // The cacheKey is a property that every scar sprite has, but not the drawn shape
    if (e.target.cacheKey && areas) {
      setLocalScarAreas([...localScarAreas, ...areas]);

      if (newLocalObjectsSize) {
        areas.forEach((area: string) => {
          const index = selectedAreas.findIndex((entry: IPainArea) => entry.name === area);

          // Add new entry to selectedAreas state if not yet being selected...
          if (index === -1) {
            selectedAreas.push({
              name: area,
              painMarksCount: 0,
              scarMarksCount: 1,
            });
          }
          // ...increase count instead
          else {
            selectedAreas[index] = {
              name: selectedAreas[index].name,
              painMarksCount: selectedAreas[index].painMarksCount,
              scarMarksCount: (selectedAreas[index].scarMarksCount as number) + 1,
            };
          }

          const pos = currentPart.selectedAreas.findIndex((item: IPainArea) => item.name === area);

          if (pos !== -1) {
            currentPart.selectedAreas[pos].scarMarksCount += 1;
          } else {
            currentPart.selectedAreas.push({
              name: area,
              painMarksCount: 0,
              scarMarksCount: 1,
            });
          }
        });
      }
    } else {
      if (areas) {
        setLocalPainAreas([...localPainAreas, ...areas]);

        if (newLocalObjectsSize) {
          areas.forEach((area: string) => {
            const index = selectedAreas.findIndex((entry: IPainArea) => entry.name === area);

            // Add new entry to selectedAreas state if not yet being selected...
            if (index === -1) {
              selectedAreas.push({
                name: area,
                painMarksCount: 1,
                scarMarksCount: 0,
              });
            }
            // ...increase count instead
            else {
              selectedAreas[index] = {
                name: selectedAreas[index].name,
                painMarksCount: (selectedAreas[index].painMarksCount as number) + 1,
                scarMarksCount: selectedAreas[index].scarMarksCount,
              };
            }

            const pos = currentPart.selectedAreas.findIndex((item: IPainArea) => item.name === area);

            if (pos !== -1) {
              currentPart.selectedAreas[pos].painMarksCount += 1;
            } else {
              currentPart.selectedAreas.push({
                name: area,
                painMarksCount: 1,
                scarMarksCount: 0,
              });
            }
          });
        }
      }
    }
  };

  interface IObject extends Path, IImageOptions {
    pathOffset: Point;
  }

  const updateSelectedAreas = (e: ICanvasEvent) => {
    // Objects hold every object data present on current canvas
    // Proof: http://fabricjs.com/docs/fabric.js.html#line361
    const objects: IObject[] | undefined = e.target?.canvas?._objects as unknown as IObject[];
    const painAreas: string[] = [];
    const scarAreas: string[] = [];

    // Sort every area selected by object type
    if (objects) {
      objects.forEach((entry: IObject) => {
        if (entry.cacheKey) {
          const areas = determineAreas(entry);

          if (areas) {
            scarAreas.push(...areas);
          }
        } else {
          const areas = determineAreas(entry);

          if (areas) {
            painAreas.push(...areas);
          }
        }
      });
      const painAreasNegativeDiff: string[] = [];
      const painAreasPositiveDiff: string[] = [];
      const scarAreasNegativeDiff: string[] = [];
      const scarAreasPositiveDiff: string[] = [];

      // For each area: if count of previously selected area greater then count of a new one - object was moved off that area, lower - object was moved over that area. Good for multiple shapes.
      if (painAreas.length) {
        painAreas.forEach((item: string) => {
          const res = painAreas.filter((x) => x === item).length - localPainAreas.filter((x) => x === item).length;
          if (res > 0 && !painAreasPositiveDiff.includes(item)) {
            painAreasPositiveDiff.push(item);
          } else if (res < 0 && !painAreasNegativeDiff.includes(item)) {
            painAreasNegativeDiff.push(item);
          }
        });
      }

      // Same as above but consider case when one shape was moved off
      if (localPainAreas.length) {
        localPainAreas.forEach((item: string) => {
          if (!painAreasNegativeDiff.includes(item)) {
            const res = localPainAreas.filter((x) => x === item).length - painAreas.filter((x) => x === item).length;
            res > 0 && painAreasNegativeDiff.push(item);
          }
        });
      }

      // Same as above but for scar marks =======
      if (scarAreas.length) {
        scarAreas.forEach((item: string) => {
          const res = scarAreas.filter((x) => x === item).length - localScarAreas.filter((x) => x === item).length;
          if (res > 0 && !scarAreasPositiveDiff.includes(item)) {
            scarAreasPositiveDiff.push(item);
          } else if (res < 0 && !scarAreasNegativeDiff.includes(item)) {
            scarAreasNegativeDiff.push(item);
          }
        });
      }

      if (localScarAreas.length) {
        localScarAreas.forEach((item: string) => {
          if (!scarAreasNegativeDiff.includes(item)) {
            const res = localScarAreas.filter((x) => x === item).length - scarAreas.filter((x) => x === item).length;
            res > 0 && scarAreasNegativeDiff.push(item);
          }
        });
      }

      // =======
      // Iterate, do the math
      if (painAreasPositiveDiff.length) {
        painAreasPositiveDiff.forEach((area: string) => {
          const index = selectedAreas.findIndex((item: IPainArea) => item.name === area);

          if (index === -1) {
            selectedAreas.push({
              name: area,
              painMarksCount: 1,
              scarMarksCount: 0,
            });
          } else {
            selectedAreas[index].painMarksCount += 1;
          }

          const pos = currentPart.selectedAreas.findIndex((entry: IPainArea) => entry.name === area);

          if (pos === -1) {
            currentPart.selectedAreas.push({
              name: area,
              painMarksCount: 1,
              scarMarksCount: 0,
            });
          } else {
            currentPart.selectedAreas[pos].painMarksCount += 1;
          }
        });
      }

      if (painAreasNegativeDiff.length) {
        painAreasNegativeDiff.forEach((area: string) => {
          const index = selectedAreas.findIndex((entry: IPainArea) => entry.name === area);
          if (index > -1) {
            selectedAreas[index].painMarksCount -= 1;
          }

          const pos = currentPart.selectedAreas.findIndex((entry: IPainArea) => entry.name === area);
          if (pos > -1) {
            currentPart.selectedAreas[pos].painMarksCount -= 1;
          }
        });
      }

      if (scarAreasPositiveDiff.length) {
        scarAreasPositiveDiff.forEach((area: string) => {
          const index = selectedAreas.findIndex((item: IPainArea) => item.name === area);

          if (index === -1) {
            selectedAreas.push({
              name: area,
              painMarksCount: 0,
              scarMarksCount: 1,
            });
          } else {
            selectedAreas[index].scarMarksCount += 1;
          }

          const pos = currentPart.selectedAreas.findIndex((entry: IPainArea) => entry.name === area);

          if (pos === -1) {
            currentPart.selectedAreas.push({
              name: area,
              painMarksCount: 0,
              scarMarksCount: 1,
            });
          } else {
            currentPart.selectedAreas[pos].scarMarksCount += 1;
          }
        });
      }

      if (scarAreasNegativeDiff.length) {
        scarAreasNegativeDiff.forEach((area: string) => {
          const index = selectedAreas.findIndex((entry: IPainArea) => entry.name === area);
          if (index > -1) {
            selectedAreas[index].scarMarksCount -= 1;
          }

          const pos = currentPart.selectedAreas.findIndex((entry: IPainArea) => entry.name === area);
          if (pos > -1) {
            currentPart.selectedAreas[pos].scarMarksCount -= 1;
          }
        });
      }

      currentPart.selectedAreas = excludeUnselectedZones(currentPart.selectedAreas);
      setSelectedAreas(excludeUnselectedZones(selectedAreas));
      setLocalPainAreas(painAreas);
      setLocalScarAreas(scarAreas);
    }
  };

  const excludeUnselectedZones = (selectedAreas: IPainArea[]): IPainArea[] => {
    // Check if there is any entrie in selectedAreas state with both deselected pain and scar marks
    return selectedAreas.filter((area: IPainArea) => area.painMarksCount > 0 || area.scarMarksCount > 0);
  };

  const setClasses = () => {
    let classname = 'sketch_field';

    if (loading) {
      classname += ' sketch_field--disabled';
    }

    return classname;
  };

  useEffect(() => {
    // .lower-canvas - one of the two that stores our drawn objects
    // .upper-canvas - the other one where the drawing takes place
    const lowerCanvas: HTMLCanvasElement | null = document.querySelector('.lower-canvas');
    const ctx: CanvasRenderingContext2D | null | undefined = lowerCanvas?.getContext('2d');

    const onImgLoad = () => {
      if (lowerCanvas) {
        // Resize the canvas to fit the image size
        lowerCanvas.width = PAIN_AREAS_PAGE_CANVAS_WIDTH;
        lowerCanvas.height = PAIN_AREAS_PAGE_CANVAS_HEIGHT;
        ctx?.drawImage(img, 0, 0);
        // Save heatmap data to state
        setHeatmapData(ctx?.getImageData(0, 0, PAIN_AREAS_PAGE_CANVAS_WIDTH, PAIN_AREAS_PAGE_CANVAS_HEIGHT));
        // Hide heatmap
        sketchFieldRef?.current?.clear();
      }
    };

    const img: HTMLImageElement = new Image();

    img.src = currentPart.heatmap;
    img.crossOrigin = 'Anonymous';
    img.addEventListener('load', onImgLoad);

    return () => {
      img.removeEventListener('load', onImgLoad);
    };
  }, [currentPart]);

  useEffect(() => {
    // If there are any objects were drawn previously - load it
    if (!currentPart.canvasData.length) {
      sketchFieldRef?.current?.clear();
    } else {
      sketchFieldRef?.current?.fromJSON(currentPart.canvasData);
    }

    setLocalPainAreas([]);
    setLocalScarAreas([]);
    setCanvasData('');
  }, [currentPart]);

  useEffect(() => {
    if (tool === ETools.Select && sketchFieldRef.current) {
      sketchFieldRef.current._fc.selection = false;
    }
  }, [tool]);

  return (
    <>
      <SketchField
        width={PAIN_AREAS_PAGE_CANVAS_WIDTH}
        height={PAIN_AREAS_PAGE_CANVAS_HEIGHT}
        tool={tool}
        lineColor={PAIN_AREAS_DRAWING_COMPONENT_LINE_COLOR}
        lineWidth={10}
        undoSteps={9999}
        ref={sketchFieldRef}
        onChange={handleChange}
        className={setClasses()}
        onObjectAdded={handleObjectAdded}
        onObjectModified={updateSelectedAreas}
        onObjectRemoved={updateSelectedAreas}
      />

      <img crossOrigin="anonymous" src={currentPart.image} alt={currentPart.name} />
      {legsFrontShown && (
        <div className="genitals__toggler">
          <CanvasControlButton icon="eye" border="round" onClick={toggleGenitalsState} disabled={!genitalsShown}>
            View genitals
          </CanvasControlButton>
        </div>
      )}

      <Row className="canvas__controls" justify="space-between">
        <CanvasControlButton
          icon="pencil"
          border="semicircular"
          onClick={handleDraw}
          transparent={tool !== ETools.Pencil}
          disabled={loading}
        >
          Draw
        </CanvasControlButton>
        <CanvasControlButton icon="plus" transparent border="semicircular" onClick={handleAddScar} disabled={loading}>
          Add scar
        </CanvasControlButton>
        <CanvasControlButton icon="undo" transparent border="round" onClick={handleUndo} disabled={loading} />
        <CanvasControlButton icon="bucket" onClick={handleClear} disabled={loading}>
          Clear all
        </CanvasControlButton>
      </Row>
    </>
  );
};
