import React from 'react';
import { Modal } from 'antd';

interface IComponentProps {
  onCancel: () => void;
  onSave: () => void;
  visible: boolean;
}

export const GoBackwardConfirmationModal: React.FC<IComponentProps> = ({ visible, onCancel, onSave }) => {
  return (
    <Modal visible={visible} centered cancelText="Cancel" onCancel={onCancel} okText="Confirm" onOk={onSave}>
      <span>
        Are you sure you want to go back?
        <br />
        If you change the pain area, the answers for the previous selection will be lost.
      </span>
    </Modal>
  );
};
